import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`How to navigate the entirety of the 3.2 popup browser with keystrokes alone`}</h1>
    <p>{`Starting in 3.2, you can now navigate the entire popup browser without using a mouse, which can be a real timesaver once you train your muscle memory. You can easily search for and access devices, presets, multisamples, filter down to specific creators and tags, and so on.`}</p>
    <ol>
      <li parentName="ol">{`First make sure that you have a shortcut key S assigned to Browser > Focus Browser Search Field.`}</li>
      <li parentName="ol">{`Next make sure that you have a shortcut key T assigned to Panel Management > Focus Track Header Area.`}</li>
      <li parentName="ol">{`Then make sure you have a shortcut key B assigned to General > Insert from Library.`}</li>
    </ol>
    <p>{`To open the Popup Browser (no matter where you're currently focused in the Bitwig UI), press T > B. By default, your cursor is focused in the search field, so start typing part of the item name you're looking for.`}</p>
    <p>{`At any point, you can STOP typing the search term and press DOWN ARROW to drop into the search results pane on the far right EVEN IF IT IS EMPTY.`}</p>
    <p>{`Once you have shifted your focus out of the search field into the results pane, you can do two things: A) Press LEFT ARROW to move into the filter columns as far as you want to go, and B) Press CTRL - RIGHT ARROW to toggle the browser focus among the five major category tabs along the top right of the Popup Browser`}</p>
    <p>{`When you've narrowed the search results to a list that contains the thing you're looking for, use RIGHT ARROW to move back into the results pane, then DOWN ARROW to select the item you want. Then press ENTER to insert that device/preset/multisample/sample and close the Popup Browser.`}</p>
    <p>{`If at any time you want shift your focus back up into the search field to further refine your search string, press S.`}</p>
    <p>{`Submitted by `}<a parentName="p" {...{
        "href": "https://discordapp.com/channels/121395249003233280/649293987848519691/715175997338681396"
      }}>{`Baphy`}</a></p>
    <h2>{`Need more?`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/tips-tricks"
        }}>{`more Bitwig Tips & Tricks`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/"
        }}>{`BitWiki Overview`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      